.bantumart_promotion_page_wrap {

  min-height: 30vh;
  background: rgb(9, 76, 89);
  padding-top: 5vh;
  
}

.promote_showcase h4 {
  margin-top: 100px;
}

.activation_wrapper {


   margin-top: 20px;
  margin-bottom: 100px; 
}

@media only screen and (max-width: 600px) {
  .activation_wrapper {
    /* padding-top: 10vh; */
    /* padding-bottom: 40vh; */

    /* margin-top: -80px;
    margin-bottom: 10px; */
  }
}

.activation_wrapper h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 80px;
}

.price_selection_wrap {
  border-radius: 10px;
  padding: 10px 10px;
  background-color: coral;

}

.price_selection_wrap h4 {
  font-size: 20px;
  margin: 0px;
  padding: 0px;
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .price_selection_wrap h4 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  .price_selection_wrap h5 {
    font-size: 13px;
    margin: 10px auto;
    padding: 0px;

    font-weight: bold;
    text-align: center;
  }
}

.freeAdd {
  opacity: 0.9;
}

.freeAdd:hover {
  cursor: pointer;
  opacity: 1 !important;
  transition: 1s ease-in-out;
}


.activation_wrapper .container {
  padding: 10px;
  border-radius: 10px;
}

.price_card h4 {
  font-size: 18px;
}
.price_card h5 {
  font-size: 16px;
}



 .activate_later{
  background: #094c59;
   padding: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
}

.activate_later h5{
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  padding:10px 0px;
}
