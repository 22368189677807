.Tabs {
    /* width: 80%; */
    height: auto;
    /* min-height: 400px; */
    /* background: #053742; */
    /* margin: 3.5rem auto 1.5rem; */
    /* padding: 2rem 1rem; */
    /* color: #e8f0f2; */
    /* border-radius: 2rem; */
  }
  
  ul.tab_header {
    width: 60%;
    /* margin: 0 auto 2rem; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    /* border-radius: 2rem; */
    padding-left: 0px;
    margin-top: 80px;
    margin-bottom: 26px;
    border-bottom: 1px solid rgb(218, 225, 231);
    border-top-color: rgb(218, 225, 231);
    border-right-color: rgb(218, 225, 231);
    border-left-color: rgb(218, 225, 231);
  }
  ul.tab_header li {
    /* width: 50%; */
    /* padding: 1rem; */
    list-style: none;
    text-align: center;
    cursor: pointer;
    /* transition: all 0.7s; */
  }
  ul.tab_header li:nth-child(2) {
    border-radius: 0;
  }
  /* ul.tab_header li:hover {
  background: rgba(50, 224, 196, 0.15);
  } */
  ul.tab_header li.active {
    border-bottom: 2px solid rgb(233, 69, 96);
    /* border-top-color: rgb(233, 69, 96);
  border-right-color: rgb(233, 69, 96);
  border-left-color: rgb(233, 69, 96); */
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 25px;
    padding: 4px 10px;
    color: rgb(233, 69, 96);
  }
  
  /* hjdfffh tabs */
  