


.round_upload{
  background-color: red;
  padding: 15px ;
  /* border-radius: 10px; */
}

.round_upload  span{
 
  color:#fff !important;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
 
  
}

.ak{
  margin-top: 15px;
}

.photo_upload_hightlight{
  border-bottom: 1px red solid;
  
}
